import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Message } from "../models/Message";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  baseUrl = "https://tidda-de-dev.zana.ai/brain";
  constructor(private http: HttpClient) {}
  getMessage(message, userId, isMedici, context?): Observable<Message> {
    if (isMedici) {
      this.baseUrl = "https://tidda-de-dev.zana.ai/brain";
    } else {
      this.baseUrl = "https://tidda-de-dev.zana.ai/brain";
    }
    const headers = {
      "Content-Type": "application/json",
      "Accept-Charset": "UTF-8",
    };
    const jsonRequest = {
      utterance: message,
      sid: "12398",
      context: "web_bot.feedback",
      lang: "de",
      agentName: "tidda-de-dev",
    };
    console.log("Json Request", jsonRequest);
    return this.http.post<Message>(this.baseUrl, jsonRequest, { headers });
  }
}
