import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { DialogData } from "./chat.component";

@Component({
  selector: "app-user-info",
  template: `<h1 mat-dialog-title>Enter your name</h1>
    <mat-form-field>
      <input
        matInput
        [(ngModel)]="data.username"
        placeholder="What's your name?"
      />
    </mat-form-field>
    <mat-dialog-actions>
      <button mat-button [mat-dialog-close]="data.username">Ok</button>
    </mat-dialog-actions>`,
})
export class UserInfoComponent {
  constructor(
    public dialogRef: MatDialogRef<UserInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
}
