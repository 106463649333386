import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ConversationMessage } from "../shared/models/ConversationMessage";
import { ChatService } from "../shared/services/chat.service";
import { Message } from "../shared/models/Message";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import * as moment from "moment";
import { SideNavService } from "../shared/services/side-nav.service";
import * as uuid from "uuid";
import { UserInfoComponent } from "./user-info.component";

export interface DialogData {
  username: string;
}

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit, OnDestroy {
  public now: Date = new Date();

  public uttId;
  @ViewChild("scrollBottom", { static: true }) private scrollBottom: ElementRef;
  userId: string;
  senderMessage: string;
  allMessages: ConversationMessage[] = [];
  isTyping: boolean;
  isMedici: boolean;
  context = "";
  username: string;
  showFullText: boolean[] = [];
  maxContentLength: number = 290;

  constructor(
    private chatService: ChatService,
    private router: Router,
    public dialog: MatDialog,
    private sideNavService: SideNavService,
    private route: ActivatedRoute
  ) {}

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.scrollBottom.nativeElement.scrollTop =
        this.scrollBottom.nativeElement.scrollHeight;
    } catch (err) {}
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((allParams) => {
      const user = allParams.get("user");
      if (user) {
        this.username = user;
        localStorage.setItem("username", this.username);
      }
    });

    if (!localStorage.getItem("userId")) {
      this.userId = uuid.v4();
      localStorage.setItem("userId", this.userId);
    } else {
      this.userId = localStorage.getItem("userId");
    }

    if (
      localStorage.getItem("username") !== null &&
      localStorage.getItem("username")
    ) {
      this.username = localStorage.getItem("username");
    } else {
      const dialogRef = this.dialog.open(UserInfoComponent, {
        width: "320px",
        data: { username: this.username },
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.username = result;
        localStorage.setItem("username", this.username);
      });
    }

    if (this.router.url == "/medici") {
      this.isMedici = true;
    }

    // Load chat history from local storage and deserialize items
    const savedChatHistoryString = this.isMedici
      ? localStorage.getItem("allmessagesChat2")
      : localStorage.getItem("allmessagesChat1");

    if (savedChatHistoryString && savedChatHistoryString.trim() !== "") {
      this.allMessages = JSON.parse(savedChatHistoryString).map(
        (item: ConversationMessage) => ({
          ...item,
          items: item.items.map((i: any) => ({ ...i })),
        })
      );
    }

    // Load showFullText from local storage and deserialize it
    const savedShowFullTextString = this.isMedici
      ? localStorage.getItem("showFullTextChat2")
      : localStorage.getItem("showFullTextChat1");

    if (savedShowFullTextString && savedShowFullTextString.trim() !== "") {
      this.showFullText = JSON.parse(savedShowFullTextString);
    } else {
      // Initialize showFullText based on the updated allMessages array
      this.initShowFullText();
    }
  }

  ngOnDestroy() {
    // Save allMessages and showFullText to local storage when the component is destroyed
    if (this.isMedici) {
      localStorage.setItem(
        "allmessagesChat2",
        JSON.stringify(this.allMessages)
      );
      localStorage.setItem(
        "showFullTextChat2",
        JSON.stringify(this.showFullText)
      );
    } else {
      localStorage.setItem(
        "allmessagesChat1",
        JSON.stringify(this.allMessages)
      );
      localStorage.setItem(
        "showFullTextChat1",
        JSON.stringify(this.showFullText)
      );
    }
  }

  initShowFullText() {
    this.showFullText = new Array(this.allMessages.length).fill(false);
  }

  sendMessage(isMedici: boolean) {
    this.isTyping = true;

    const conversationMessages: ConversationMessage = {
      senderMessage: this.senderMessage,
      items: [],
      senderTime: this.now.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      }),
    };

    const inputText = this.senderMessage;
    this.senderMessage = "";

    this.chatService
      .getMessage(inputText, this.userId, isMedici, this.context)
      .subscribe(
        (messages: Message) => {
          if (messages.context) {
            const newContent = messages.context
              .replace(/<br>/gi, "\n")
              .replace(/<\/br>/gi, "\n");
            this.context = newContent;
          }

          let newContent = "";
          messages.items.forEach((item) => {
            if (!item.content) {
              newContent = item.title;
            } else {
              newContent = item.content
                .replace(/<br>/gi, "\n")
                .replace(/<\/br>/gi, "\n");
            }
            conversationMessages.items.push({ ...item, content: newContent });
          });

          conversationMessages.botMessage = messages.message;
          conversationMessages.botMessageTime = moment(
            messages.timestamp
          ).format("HH:mm, MM-DD-YYYY");

          conversationMessages.context = messages.context;
          conversationMessages.mainItem = messages.items.find(
            (message) => message.type_template === "list"
          );

          // Push the new message to allMessages
          this.allMessages.push(conversationMessages);

          // Push a corresponding entry to showFullText (same length as allMessages)
          this.showFullText.push(false);

          this.isTyping = false;
        },
        (error) => {
          console.log("error", error);
        }
      );
  }

  goToLink(url: string) {
    window.open(url, "_self");
  }

  toggleSideNav(): void {
    this.sideNavService.toggle();
  }

  writeMessage(item: string) {
    this.senderMessage = item;
    this.sendMessage(this.isMedici);
  }
}
