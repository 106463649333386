import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ConversationMessage } from "../shared/models/ConversationMessage";
import { ChatService } from "../shared/services/chat.service";
import { Message } from "../shared/models/Message";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import * as moment from "moment";
import { SideNavService } from "../shared/services/side-nav.service";
import * as uuid from "uuid";
import { UserInfoComponent } from "./user-info.component";

@Component({
  selector: "app-type",
  templateUrl: "./type.component.html",
  styleUrls: ["./type.component.scss"],
})
export class TypeComponent implements OnInit {
  public chatType;
  public now: Date = new Date();

  public uttId;
  @ViewChild("scrollBottom", { static: true }) private scrollBottom: ElementRef;
  userId: string;
  senderMessage: string;
  allMessages: ConversationMessage[] = [];
  isTyping: boolean;
  isMedici: boolean;
  context = "";
  username: string;

  constructor(
    private chatService: ChatService,
    private router: Router,
    public dialog: MatDialog,
    private sideNavService: SideNavService,
    private route: ActivatedRoute
  ) {}

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.scrollBottom.nativeElement.scrollTop =
        this.scrollBottom.nativeElement.scrollHeight;
    } catch (err) {}
  }

  ngOnInit() {
    let type = this.route.snapshot.paramMap.get("type");
    console.log("type = ", type);
    this.chatType = type;
    console.log("chatType = ", this.chatType);

    this.route.queryParamMap.subscribe((allParams) => {
      console.log(allParams.get("user"));
      const user = allParams.get("user");
      if (user) {
        this.username = user;
        localStorage.setItem("username", this.username);
      }
    });
    if (!localStorage.getItem("userId")) {
      this.userId = uuid.v4();
      localStorage.setItem("userId", this.userId);
    } else {
      this.userId = localStorage.getItem("userId");
    }
    if (
      localStorage.getItem("username") !== null &&
      localStorage.getItem("username")
    ) {
      this.username = localStorage.getItem("username");
    } else {
      const dialogRef = this.dialog.open(UserInfoComponent, {
        width: "320px",
        data: { username: this.username },
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.username = result;
        localStorage.setItem("username", this.username);
      });
    }
    if (this.router.url == "/medici") {
      this.isMedici = true;
      if (localStorage.getItem("allmessagesChat2")) {
        if (localStorage.getItem("allmessagesChat2").length !== 0) {
          this.allMessages = JSON.parse(
            localStorage.getItem("allmessagesChat2")
          );
        }
      }
    } else {
      if (localStorage.getItem("allmessagesChat1")) {
        if (localStorage.getItem("allmessagesChat1").length !== 0) {
          this.allMessages = JSON.parse(
            localStorage.getItem("allmessagesChat1")
          );
        }
      }
    }

    this.route.queryParamMap.subscribe((allParams) => {
      console.log("allParams.get('utterance') = ", allParams.get("utterance"));
      const utterance = allParams.get("utterance");
      if (utterance) {
        this.senderMessage = utterance;
        this.sendMessage(this.isMedici);
      } else {
        this.senderMessage = type;
        this.sendMessage(this.isMedici);
      }
    });
    this.scrollToBottom();
  }

  sendMessage(isMedici: boolean) {
    this.isTyping = true;
    const conversationMessages: ConversationMessage = {
      senderMessage: this.senderMessage,
      items: [],
      senderTime: this.now.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      }),
    };
    this.allMessages.push(conversationMessages);
    const inputText = this.senderMessage;
    this.senderMessage = "";
    this.chatService
      .getMessage(inputText, this.userId, isMedici, this.context)
      .subscribe(
        (messages: Message) => {
          if (messages.context) {
            this.context = messages.context;
          }
          conversationMessages.botMessage = messages.message;
          conversationMessages.botMessageTime = moment(
            messages.timestamp
          ).format("HH:mm, MM-DD-YYYY");
          messages.items.forEach((item) => {
            conversationMessages.items.push(item);
          });
          conversationMessages.context = messages.context;
          conversationMessages.mainItem = messages.items.filter((message) => {
            return message.type_template === "list";
          })[0];

          if (isMedici) {
            localStorage.setItem(
              "allmessagesChat2",
              JSON.stringify(this.allMessages)
            );
          } else {
            localStorage.setItem(
              "allmessagesChat1",
              JSON.stringify(this.allMessages)
            );
          }
          this.isTyping = false;
        },
        (error) => {
          console.log("error");
        }
      );
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  toggleSideNav(): void {
    this.sideNavService.toggle();
  }

  writeMessage(item: string) {
    this.senderMessage = item;
    this.sendMessage(this.isMedici);
  }
}
