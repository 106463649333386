import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
//import { BedarfsanalyseComponent } from './bedarfsanalyse/bedarfsanalyse.component';
import {ChatComponent} from './chat/chat.component';
import { TypeComponent } from './type/type.component';
// import { TechnologiesucheComponent } from './technologiesuche/technologiesuche.component';

const routes: Routes = [
  {path: '', redirectTo: '/bot', pathMatch: 'full'},
  {path: 'bot', component: ChatComponent},
  //{path: 'bot/:Bedarfsanalyse', component: BedarfsanalyseComponent},
  {path: 'bot/:type', component: TypeComponent},
  // {path: 'bot/:Technologiesuche', component: TechnologiesucheComponent},
  // {path: 'bot/?u=Bedarfsanalyse', component: ChatComponent},
  {path: 'medici', component: ChatComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
export const routingComponents = [TypeComponent]
